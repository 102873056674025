import { FC } from 'react';
import { PictureProps } from '../../atoms/Picture';
import { SocialsProps } from '../../atoms/Socials';
import { LinkProps } from '../../atoms/Link';
import { FooterMenuProps } from '../FooterMenu';
import BackToTop from '../../atoms/BackToTop';
import * as SC from './styled';
export type FooterProps = {
  className?: string;
  imageProps: PictureProps;
  socialsProps?: SocialsProps;
  legalLinks?: LinkProps[];
  menuProps?: FooterMenuProps;
  customizeCookies?: LinkProps;
};
const Footer: FC<FooterProps> = ({
  className,
  imageProps,
  socialsProps,
  legalLinks,
  menuProps,
  customizeCookies
}) => {
  return <>
      <SC.Main className={className}>
        <SC.Wrapper>
          <SC.MenuWrapper>
            <SC.Section $alignSelf="flex-start" $flexDirection="column" $alignItems="start">
              <SC.Logo {...imageProps} />
              {socialsProps && <SC.StyledSocial {...socialsProps} />}
            </SC.Section>
            {menuProps && <SC.Menu {...menuProps} />}
          </SC.MenuWrapper>
          <SC.Separator />
          <SC.LegalLinksWrapper>
            <SC.LegalLinks>
              {customizeCookies && <SC.Legal {...customizeCookies} />}
              {legalLinks?.map((link, i) => <SC.Legal key={i} {...link} />)}
            </SC.LegalLinks>
          </SC.LegalLinksWrapper>
        </SC.Wrapper>
      </SC.Main>
      <BackToTop />
    </>;
};
export default Footer;